html {
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #313131;
}

h2 {
  padding-right: 30px;
  font-size: 30px;
}

.Navbar nav {
  position: relative;
  box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 110px;
  z-index: 9;
}
.Navbar nav a.logo {
  position: absolute;
  width: 30%;
  left: 3%;
  top: 10%;
  text-align: left;
  z-index: 10;
}
.Navbar a img {
  width: 100%;
  max-width: 400px;
}
.Navbar ul {
  list-style-type: none;
  margin: 0;
  position: absolute;
  top: 14%;
}

.Navbar ul li {
  float: right;
}

.Navbar ul li a {
  display: block;
  color: #ce324d;
  text-align: center;
  padding: 20px;
  padding-bottom: 10px;
  text-decoration: none;
  font-size: 30px;
  font-weight: 600;
  transition: all 0.5s;
}

.Navbar ul li a:hover {
  border-bottom: 3px solid #ce324d;
  padding: 18px;
  padding-bottom: 10px;
}

.HeaderSection .image-main img {
  width: 100%;
  margin: auto;
}

.HeaderSection .image-main img.desktop {
  display: block;
}

.HeaderSection .image-main img.mobile {
  display: none;
}

.image-main.sub-page {
  overflow: hidden;
  position: relative;
  height: 240px;
}

.image-main .img-overlay {
  display: none;
}

.sub-page .img-overlay {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  background: #f0eff3b3;
  text-align: center;
  padding: 60px 0 0 0;
  font-size: 80px;
  height: 240px;
  margin: 0;
  font-weight: 500;
}

.menu-toggler {
  display: none;
}

/* __________conversion section________________ */

.conversion-section {
  min-height: 10vh;
  background-color: #ce324d;
  width: 100%;
  box-shadow: 0px -4px 7px 0px rgb(12 12 12 / 54%), 0px 5px 8px 0 rgb(0 0 0 / 42%);
  z-index: 8;
  position: relative;
  text-align: center;
}

.conversion-section h1 a, .conversion-section h1 a:visited
.conversion-section h1 a:focus {
  color: white;
  text-decoration: none;
  margin-right: 30px;
  font-weight: 700;
}

.conversion-section h1 {
  color: white;
  font-weight: 400;
  font-size: 40px;
  padding: 30px;
  margin: 0;
}

.conversion-section h1 span {
  font-weight: 700;
}


/* _____________ fields section ____________________ */

.fields-section {
  overflow: auto; 
  position: relative;
  min-height: 60vh;
  background-color: white;
  width: 100%;
  margin: 0;
  padding: 50px 0;
}

.fields-section .section-header {
  width: 80%;
  margin: auto;
}

.fields-section .section-header h2 {
  display: inline-block;
}
.fields-section .section-header span {
  display: inline-block;
  margin-right: 20px;
  font-size: 18px;
}

.fields-section .fields-wrapper {
  width: 80%;
  margin: 50px auto;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  column-gap: 3.5%;
  row-gap: 80px;
  text-align: center;
}

.field-item {
  cursor: pointer;
}

.fields-section .fields-wrapper .field-icon img {
  filter: grayscale(1);
  width: 80%;
  border-radius: 1rem;
}

.fields-section .fields-wrapper .field-text {
  font-size: 20px;
}

.react-responsive-modal-modal {
  max-width: unset !important;
  text-align: center !important;
  width: 45%;
  border-radius: 0.5rem;
  border-bottom: 3px solid #ce324d;
}

.react-responsive-modal-modal p {
  margin: 31px 0;
  padding: 0 50px;
  text-align: justify;
  font-size: 18px;
  line-height: 38px;
}

.react-responsive-modal-modal button.custom-close {
  float: left;
  margin-left: 20px;
  padding: 10px 30px;
  color: #ce324d;
  background-color: white;
  font-size: 18px;
  font-weight: 600;
  /* outline: none; */
  border: 2px solid #ce324d;
  transition: 0.5s all;
  cursor: pointer;
}

.react-responsive-modal-modal button.custom-close:hover {
  color: white;
  background-color: #ce324d;
  box-shadow: 2px 2px 7px 0px rgb(12 12 12 / 92%);
  border: none;
  border: 2px solid #ce324d;
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 14px;
  right: 14px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  /* outline: none; */
}


/* __________________________________ */

.about-section {
  position: relative;
  overflow: auto;
  min-height: 80vh;
  background-color: #f1f1f1;
  width: 100%;
  margin: 0;
  padding: 50px 0;
  color: black;
}

.about-section .section-header {
  width: 80%;
  margin: auto;
}

.about-section .section-header h2 span {
 margin-right: 15px;
 color: #ce324d;
}

.about-section .about-wrapper{
  width: 80%;
  margin: 50px auto;
  display: grid;
  grid-template-columns: 62% 28%;
  grid-column-gap: 1%;
  column-gap: 10%;
}

.about-section .about-wrapper .text{
  font-size: 20px;
  text-align: justify;
}

.about-section .about-wrapper .image .profile-img{
  border-radius: 0.5rem;
  filter: sepia(50%) brightness(120%);
  width: 100%;
}

.about-section .about-wrapper .image .image-badge{
  border-bottom: 15px solid #ce324d;
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  margin-top: -3px;
}

.about-section .about-wrapper .image .cert{
  position: absolute;
  top: 150px;
  left: 9%;
  width: 120px;
  filter: grayscale(1);
}




/* __________________________________ */

.methods-section {
  overflow: auto; 
  position: relative;
  min-height: 60vh;
  background-color: white;
  width: 100%;
  margin: 0;
  padding: 50px 0;
}

.method-text {
  font-size: 20px;
  background-color: #ce324d;
  max-width: 230px;
  margin: auto;
  transition: 0.5s all;
  color: white;
  box-shadow: 0px -4px 7px 0px rgb(12 12 12 / 92%);
  padding: 5px;
  border-radius: 0.5rem;
}

.methods-section .section-header {
  width: 80%;
  margin: 50px auto;
}

.methods-section .methods-wrapper {
  width: 80%;
  margin: 50px auto;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  column-gap: 3.5%;
  row-gap: 80px;
  text-align: center;
}

.methods-section .methods-wrapper .method-icon {
  width: 100%;
  min-height: 110px;
}

.methods-section .methods-wrapper .method-icon img{
  width: 70px;
}

/* ___________ contact section: ________________*/

.contact-section {
  position: relative;
  overflow: auto; 
  width: 100%;
  margin: 0;
  padding: 50px 0;
  background-color: #383838;
  min-height: 50vh;
}

.contact-section .section-header {
  color: #ffffff;
  width: 80%;
  margin: 30px auto 0 auto;
}

.contact-wrapper {
  width: 80%;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 48% 48%;
  column-gap: 4%;
  color: white;
}

.contact-form{
 margin-right: 30px;
 font-size: 20px;
 font-weight: 600;
}

.contact-form div{
 margin-top: 25px;
}

.contact-form .phone-wrp, .contact-form .mail-wrp{
  color: white;
  font-weight: 600;
  font-size: 25px;
  border: 1px solid #ce324d;
  padding: 5px 20px 5px 5px;
  box-shadow: -5px 0px 7px 0px rgb(12 12 12 / 92%);
}

.contact-form a, .contact-form a:visited{
 color: white;
 text-decoration: none;
 margin-right: 10px;
}

.image-wrp  {
  width: 60%;
  margin: auto;
}

.image-wrp img {
  width: 100%;
  border-radius: 0.5rem;
  filter: brightness(120%);
  /* transform: rotate(355deg); */
}


/* _______ footer-section: ___________ */

.footer-section {
  position: relative;
  overflow: auto; 
  width: 100%;
  margin: 0;
  padding: 50px 0;
  background-color: #ce324d;
  min-height: 10vh;
  color: white;
  text-align: center;
  box-shadow: 0px -4px 7px 0px rgb(12 12 12 / 92%);
}

.footer-section div a {
  color: white;
  text-decoration: none;
  border: 1px solid white;
  padding: 5px 10px;
  display: block;
  width: fit-content;
  margin: 15px auto;
}

.footer-section div a i {
  font-size: 18px;
}

.text-contact {
  text-align: center;
  margin: 10px;
}

.sub-text-contact {
  width: 50%;
  margin: auto;
  text-align: center;
}

.sub-text-contact h4 {
  margin: 0 10px 10px 0;
  text-align: center;
  display: inline-block;
  font-weight: 500;
}


.scroll-top-wrp {
  position: fixed;
  padding: 5px;
  transition: all 0.5s;
  width: 70px;
  height: 63px;
  background-color: #ffffffb8;
  box-shadow: 2px 2px 18px 0px rgb(0 0 0 / 16%), 0px -4px 7px 0px rgb(12 12 12 / 92%);
  bottom: 15px;
  left: 15px;
  z-index: 1000;
  opacity: 1;
  cursor: pointer;
}

.scroll-top-wrp:hover {
  box-shadow: none;
  transform: scale(0.9);
}

.hide-smooth {
  opacity: 0;
  height: 15px;
}

.scroll-top {
  padding: 7px 2px;
  color: white;
  text-align: center;
  background-color: #383838;
}


/* ______________ accessibility  page ________________ */

a.access-link {
  display: block;
  text-align: center;
  padding: 5px;
  font-size: 18px;
  font-weight: 600;
  color: #212121;
  border: 1px solid #212121;
  text-decoration: none;
  margin: 8px;
}

.declaration-wrp {
  width: 80%;
  margin: 50px auto 80px auto;
  font-size: 18px;
}

.access-type {
  margin: 15px;
  border-right: 2px solid #ce324d;
  padding-right: 10px;
}

.access-type.sub-access {
  margin-right: 35px;
  border-right: 2px solid #ff000054;
}

.sub-access:first-of-type {
  margin-top: 30px;
}

.declaration-wrp h2 {
  padding-right: 0;
  margin-bottom: 30px;
}

p.first-p {
  line-height: 30px;
  margin: 10px;
}

p.first-p a {
  color: #ce324d;
  text-decoration: none;
}


/* ____ regular desktop _______ */

@media screen and ( max-width: 1400px ) and (min-width: 901px) {
  .Navbar nav {
   height: 90px;
  }

  .Navbar ul {
    padding-right: 0;
    margin-right: 1%;
}

  .Navbar nav a.logo {
    position: absolute;
    width: 26%;
    max-width: 350px;
    min-width: 260px;
    left: 3%;
    top: 10%;
    text-align: left;
    z-index: 10;
  }

  .Navbar ul li a {
    font-size: 24px;
    padding: 13px;
  }

  .react-responsive-modal-modal {
    width: 60%;
  }

  .about-section .about-wrapper .image .cert {
    width: 100px;
  }
  
  .contact-form .phone-wrp, .contact-form .mail-wrp {
    font-size: 20px;
  }
}


/* __________ mobile _________ */

@media screen and (max-width: 900px){

  .HeaderSection .image-main img.desktop {
    display: none;
  }
  
  .HeaderSection .image-main img.mobile {
    display: block;
  }

  .Navbar .menu-toggler {
    display: block;
    position: absolute;
    top: 28%;
    right: 4%;
    width: 40px;
    padding: 0;
    z-index: 9999;
    background-color: white;
    border: none;
    font-size: 25px;
    color: #ce324d;
    cursor: pointer;
    /* outline: none; */
  }

  .Navbar nav {
    height: 90px;
  }

  .Navbar nav a.logo {
    width: 40%;
    min-width: 260px;
  }

  .Navbar nav ul {
    position: absolute;
    right: 0;
    top: 70px;
    width: 100%;
    height: 0;
    transition: 1s all;
    padding: 0;
    z-index: 99999;
    overflow: hidden;
  }

  .Navbar nav ul.display{
    height: 370px;
    background-color: white;
  }

  .Navbar ul li {
    display: block;
    /* height: 0; */
    float: none;
    background-color: white;
    width: 100%;
    border-bottom: 2px solid #ce324d1c;
  }

  .Navbar ul li a {
    color: #ce324d;
    font-size: 25px;
  }
  
  .conversion-section h1 {
    font-size: 25px;
    padding: 15px;
  }

  .conversion-section h1 a {
    margin-right: 15px;
    display: block;
  }

  .fields-section .fields-wrapper {
    width: 90%;
    grid-template-columns: 100%;
  }
  
  h2 {
    padding-right: 20px;
    font-size: 25px;
    margin: 0px;
  }
  
  .react-responsive-modal-modal {
    width: 75%;
  }

  .react-responsive-modal-modal h2 {
    margin: 0;
    margin-top: 30px;
    padding: 0;
  }

  .react-responsive-modal-modal p {
    margin: 31px 0;
    padding: 0 5px;
    text-align: justify;
    font-size: 16px;
    line-height: 30px;
  }

  .about-section .section-header h2 span {
    margin-right: 0;
    color: #ce324d;
    display: block;
    font-size: 24px;
  }

  .about-section .about-wrapper {
    width: 80%;
    margin: 50px auto;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 50px;
  }

  .image {
    position: relative;
  }

  .about-section .about-wrapper .text {
    font-size: 18px;
    text-align: justify;
  }

  .about-section .about-wrapper .image .cert {
    position: absolute;
    top: -20px;
    left: -20px;
    width: 40%;
    filter: grayscale(1);
  }

  .methods-section .methods-wrapper {
    width: 80%;
    margin: 50px auto;
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 3.5%;
    column-gap: 3.5%;
    grid-row-gap: 80px;
    row-gap: 80px;
    text-align: center;
  }

  .methods-section .methods-wrapper .method-icon img {
    width: 90px;
  }

  .contact-wrapper {
    width: 85%;
    margin: 20px auto;
    display: grid;
    grid-template-columns: 100%;
    row-gap: 50px;
    color: white;
  }

  .contact-form {
    margin-right: 0;
    font-size: 20px;
    font-weight: 600;
  }

  .contact-form .phone-wrp, .contact-form .mail-wrp {
    font-size: 22px;
  }

  .contact-form a, .contact-form a:visited {
    color: white;
    text-decoration: none;
    margin: 7px 0;
    display: block;
  }
  
  .image-wrp {
    width: 90%;
    margin: 0px auto;
  }
}

@media screen and (max-width: 650px){

  .Navbar nav {
    height: 70px;
  }

  .Navbar .menu-toggler {
    top: 25%;
    right: 3%;
  }

  .Navbar nav a.logo {
    min-width: 250px;
  }

  .image-main.sub-page, .sub-page .img-overlay {
    height: 180px;
    font-size: 40px;
  }
}